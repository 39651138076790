<template>
  <v-btn  color="primary"  icon large>
    <v-icon @click="openEdit()">mdi-pencil</v-icon>

    <v-dialog
      v-model="dialog_edit"
      @keydown.esc="dialog_edit = false"
      persistent
      max-width="600px"
    >
      <v-col>
        <base-material-card
          color="primary"
          inline
          title="Editar datos del comercial"
        >
          <v-form class="pt-1">
            <v-text-field
              label="Nombre"
              v-model="nombreformnuevo"
              outlined
              class="ml-4"
            />
            <v-text-field
              :rules="rulesEmail"
              label="Email"
              v-model="correoformnuevo"
              outlined
              class="ml-4"
            />

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                id="checkLogin"
                class="v-btn-login"
                @click="confirmarEdit()"
                color="primary"
                v-show="canGuardar"
                type="submit"
                form="check-login-form"
                >Modificar</v-btn
              >
              <v-btn
                data-test="vDeleteSalir"
                color="primary"
                text
                @click="dialog_edit = false"
                >Salir</v-btn
              >
            </v-card-actions>
          </v-form>
        </base-material-card>
      </v-col>
    </v-dialog>
  </v-btn>
</template>

<script>
export default {
    components: {
        'base-material-card': () => import('./base/MaterialCard.vue')
    },
    name: 'ComercialUpdateComercial',
    data: () => ({
        url: process.env.VUE_APP_API_URL,
        nombreformnuevo: '',
        correoformnuevo: '',
        dialog_edit: false,
        updatedComercial: '',
        vuex_comerciales: [],
        UpdateIndex: '',
        rulesEmail: [
            (v) =>
                /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
                    v
                ) || 'Introduzca una dirección de correo electronico.'
        ],
        rules: [
            (v) =>
                /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/.test(
                    v
                ) ||
        'Introduzca una contraseña de al menos 6 carácteres que tenga números y letras.'
        ]
    }),
    computed: {
        canGuardar: function () {
            const patternCorreo =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/

            return (
                this.correoformnuevo !== '' &&
          this.nombreformnuevo !== '' &&
          patternCorreo.test(this.correoformnuevo)
            )
        }
    },
    created () {
        this.nombreformnuevo = this.itemEntrada.nombre
        this.correoformnuevo = this.itemEntrada.email
    },
    methods: {

        openEdit () {
            var this_comercial = this.itemEntrada
            this.nombreform = this_comercial.nombre
            this.correoformnuevo = this_comercial.email

            this.dialog_edit = true
        },
        confirmarEdit () {
            this.updatedComercial = this.itemEntrada
            this.updatedComercial = {
                nombre: this.nombreformnuevo,
                email: this.correoformnuevo
            }
            var id = this.itemEntrada.id

            const goUpdate = async () => {
                const config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: this.$store.getters.getToken
                    }
                }
                try {
                    return await this.$axios.put(
                        this.url + '/comerciales/' + id,
                        this.updatedComercial,
                        config
                    )
                } catch (error) {
                    if (error.response.status === 401) {
                        this.$store.dispatch('setToken', '')
                        this.$store.dispatch('setComercial', '')
                        this.$router.push('/').catch(() => {})
                    }
                    if (error.response.status === 422) {
                        this.$store.commit('SET_COMERCIALES_EDITAR', error.response)
                        this.dialog = false
                    }
                }
            }
            const processUpdateResult = async () => {
                const update_result = await goUpdate()
                if (update_result) {
                    if (update_result.data.success === true) {
                        this.$store.commit('SET_COMERCIALES_EDITAR', update_result)
                        const vuex_comerciales = this.$store.getters.getComerciales
                        this.UpdateIndex = vuex_comerciales.indexOf(this.itemEntrada)
                        this.dialog = false
                        if (this.UpdateIndex > -1) {
                            vuex_comerciales[this.UpdateIndex].nombre =
                this.updatedComercial.nombre
                            vuex_comerciales[this.UpdateIndex].email =
                this.updatedComercial.email

                            this.$store.commit('SET_COMERCIALES', vuex_comerciales)
                        }
                    } else {
                        this.$store.commit('SET_COMERCIALES_EDITAR', update_result)
                    }
                }
            }
            processUpdateResult()
            this.dialog_edit = false
        }
    },
    props: {
        itemEntrada: {
            type: Object
        }
    }
}
</script>
<style >

</style>
