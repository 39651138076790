<template >
  <v-container>
    <tableComerciales />
  </v-container>
</template>
<script>
import tableComerciales from './components/tableComerciales.vue'
export default {
    name: 'comerciales',
    components: {
        tableComerciales
    },
    data: () => ({})
}
</script>
