var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"section"}},[_c('v-card',{attrs:{"color":"white"}},[_c('v-card-title',[_c('v-icon',{staticClass:"iconosVcards",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-account")]),_c('v-row',[_c('v-col',[_c('div',{staticClass:"cabecera-tabla"},[_vm._v("Comerciales")])]),_c('v-col',{staticClass:"posicionBotonAgregar"},[_c('div',[_c('ComercialAdd')],1)])],1)],1),_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Buscar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"no-data-text":"No existen comerciales creados","headers":_vm.headers,"items":_vm.comerciales,"search":_vm.search,"multi-sort":"","header-props":_vm.headerProps,"footer-props":{
        'items-per-page-text': 'Elementos por página:',
        'items-per-page-all-text': 'Todos',
      }},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength))]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('ComercialUpdate',{attrs:{"itemEntrada":item}}),_c('ComercialUpdatePass',{attrs:{"itemEntrada":item}})]}},{key:"item.estados",fn:function(ref){
      var item = ref.item;
return [_c('ComercialUpdateEstado',{attrs:{"itemEntrada":item}})]}},{key:"item.estadoActivo",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.estadoActivo),expression:"item.estadoActivo"}],staticClass:"ma-2",attrs:{"color":" primary","text-color":"white"}},[_vm._v(" Activo ")]),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(!item.estadoActivo),expression:"!item.estadoActivo"}],staticClass:"ma-2",attrs:{"color":"orange","text-color":"white"}},[_vm._v(" Desactivado ")])]}}],null,true)}),_c('v-snackbar',{attrs:{"bottom":true,"right":true,"timeout":5000,"color":_vm.snack_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snack_text)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }