<template>
  <v-btn color="primary" icon large>
    <v-icon @click="openEdit()">mdi-lock-reset</v-icon>

    <v-dialog
      v-model="dialog_edit"
      @keydown.esc="dialog_edit = false"
      persistent
      max-width="600px"
    >
      <v-col>
        <base-material-card
          color="primary"
          inline
          title="Editar contraseña del comercial"
        >
          <v-form class="pt-1">
            <v-text-field
              style="margin-top: 20px"
              :rules="rules"
              type="password"
              label="Contraseña"
              v-model="password1"
              outlined
              class="ml-4"
            />
            <v-text-field
              :rules="rules"
              type="password"
              label="Repita la contraseña"
              v-model="password2"
              outlined
              class="ml-4"
            />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                id="checkLogin"
                class="v-btn-login"
                @click="confirmarEdit()"
                color="primary"
                v-show="canGuardar"
                type="submit"
                form="check-login-form"
                >Modificar</v-btn
              >
              <v-btn
                data-test="vDeleteSalir"
                color="primary"
                text
                @click="dialog_edit = false"
                >Salir</v-btn
              >
            </v-card-actions>
          </v-form>
        </base-material-card>
      </v-col>
    </v-dialog>
  </v-btn>
</template>

<script>
export default {
    components: {
        'base-material-card': () => import('./base/MaterialCard.vue')
    },
    name: 'ComercialUpdateComercial',
    data: () => ({
        url: process.env.VUE_APP_API_URL,
        password1: '',
        password2: '',
        dialog_edit: false,
        updatedComercial: '',
        vuex_comerciales: [],
        UpdateIndex: '',
        dialog2: false,
        rules: [
            (v) =>
                /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/.test(
                    v
                ) ||
        'Introduzca una contraseña de al menos 6 carácteres que tenga números y letras.'
        ]
    }),
    computed: {
        canGuardar: function () {
            const patterPass =
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/
            return (
                this.password1 !== '' &&
        this.password2 !== '' &&
        patterPass.test(this.password1) &&
        patterPass.test(this.password2)
            )
        }
    },

    methods: {
        confirmar () {
            if (this.password1 !== this.password2) {
                this.go('Contraseñas diferentes', 'error')
            } else {
                this.dialog2 = true
            }
        },
        openEdit () {
            var this_comercial = this.itemEntrada
            this.password1 = this_comercial.password

            this.dialog_edit = true
        },
        confirmarEdit () {
            this.updatedComercial = this.itemEntrada
            this.updatedComercial = {
                password: this.password1
            }
            var id = this.itemEntrada.id
            const goUpdate = async () => {
                const config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: this.$store.getters.getToken
                    }
                }
                try {
                    return await this.$axios.put(
                        this.url + '/comerciales/' + id,
                        this.updatedComercial,
                        config
                    )
                } catch (error) {
                    if (error.response.status === 401) {
                        this.$store.dispatch('setToken', '')
                        this.$store.dispatch('setComercial', '')
                        this.$router.push('/').catch(() => {})
                    }
                    if (error.response.status === 422) {
                        this.$store.commit('SET_COMERCIALES_EDITAR', error.response)
                        this.dialog = false
                    }
                }
            }
            const processUpdateResult = async () => {
                const update_result = await goUpdate()
                if (update_result) {
                    if (update_result.data.success === true) {
                        this.$store.commit('SET_COMERCIALES_EDITAR', update_result)
                        const vuex_comerciales = this.$store.getters.getComerciales
                        this.UpdateIndex = vuex_comerciales.indexOf(this.itemEntrada)
                        this.dialog = false
                        if (this.UpdateIndex > -1) {
                            vuex_comerciales[this.UpdateIndex].nombre =
                this.updatedComercial.nombre
                            vuex_comerciales[this.UpdateIndex].email =
                this.updatedComercial.email
                            vuex_comerciales[this.UpdateIndex].password =
                this.updatedComercial.password
                            this.$store.commit('SET_COMERCIALES', vuex_comerciales)
                        }
                    } else {
                        this.$store.commit('SET_COMERCIALES_EDITAR', update_result)
                    }
                }
            }
            processUpdateResult()
            this.dialog_edit = false
        }
    },
    props: {
        itemEntrada: {
            type: Object
        }
    }
}
</script>
<style >
.botonMostrarPass {
  margin-top: -15px;
  margin-left: 15px;
}
</style>
