<template>
  <v-btn class="mx-2" color="primary" fab icon large>
    <v-chip
      class="ma-2"
      color=" primary"
      v-show="itemEntrada.estadoActivo"
      text-color="white"
      @click="modificarEstado()"
    >
      Activado
    </v-chip>
    <v-chip
      class="ma-2"
      color="orange"
      v-show="!itemEntrada.estadoActivo"
      text-color="white"
      @click="modificarEstado()"
    >
      Desactivado
    </v-chip>
  </v-btn>
</template>

<script>
export default {
    name: 'ComercialUpdateComercial',
    data: () => ({
        url: process.env.VUE_APP_API_URL,
        vuex_comerciales: [],
        UpdateIndex: ''
    }),
    methods: {
        modificarEstado () {
            let nuevoEstado = ''
            if (this.itemEntrada.estadoActivo) {
                nuevoEstado = false
            } else {
                nuevoEstado = true
            }

            this.updatedComercial = this.itemEntrada
            this.updatedComercial = {
                estadoActivo: nuevoEstado
            }
            var id = this.itemEntrada.id
            const goUpdate = async () => {
                const config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: this.$store.getters.getToken
                    }
                }
                try {
                    return await this.$axios.put(
                        this.url + '/comerciales/' + id,
                        this.updatedComercial,
                        config
                    )
                } catch (error) {
                    if (error.response.status === 401) {
                        this.$store.dispatch('setToken', '')
                        this.$store.dispatch('setComercial', '')
                        this.$router.push('/').catch(() => {})
                    }
                    if (error.response.status === 422) {
                        this.$store.commit('SET_COMERCIALES_EDITAR', error.response)
                        this.dialog = false
                    }
                }
            }
            const processUpdateResult = async () => {
                const update_result = await goUpdate()
                if (update_result) {
                    if (update_result.data.success === true) {
                        this.$store.commit('SET_COMERCIALES_EDITAR', update_result)
                        const vuex_comerciales = this.$store.getters.getComerciales
                        this.UpdateIndex = vuex_comerciales.indexOf(this.itemEntrada)
                        this.dialog = false
                        if (this.UpdateIndex > -1) {
                            vuex_comerciales[this.UpdateIndex].nombre =
                this.updatedComercial.nombre
                            vuex_comerciales[this.UpdateIndex].email =
                this.updatedComercial.email
                            vuex_comerciales[this.UpdateIndex].password =
                this.updatedComercial.password
                            this.$store.commit('SET_COMERCIALES', vuex_comerciales)
                        }
                    } else {
                        this.$store.commit('SET_COMERCIALES_EDITAR', update_result)
                    }
                }
            }
            processUpdateResult()
            this.dialog_edit = false
        }
    },
    props: {
        itemEntrada: {
            type: Object
        }
    }
}
</script>
