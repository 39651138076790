<template>
  <v-container tag="section">
    <v-card color="white">
      <v-card-title>
        <v-icon size="40" color="white" class="iconosVcards"
          >mdi-account</v-icon
        >
        <v-row>
          <v-col>
            <div class="cabecera-tabla">Comerciales</div>
          </v-col>
          <v-col class="posicionBotonAgregar">
            <div><ComercialAdd /></div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto"
        label="Buscar"
        hide-details
        single-line
        style="max-width: 250px"
      />

      <v-divider class="mt-3" />

      <v-data-table
        no-data-text="No existen comerciales creados"
        :headers="headers"
        :items="comerciales"
        :search.sync="search"
        multi-sort
        :header-props="headerProps"
        :footer-props="{
          'items-per-page-text': 'Elementos por página:',
          'items-per-page-all-text': 'Todos',
        }"
      >
        <template v-slot:[`footer.page-text`]="items"
          >{{ items.pageStop }} de {{ items.itemsLength }}</template
        >
        <template v-slot:[`item.action`]="{ item }">
          <ComercialUpdate v-bind:itemEntrada="item"></ComercialUpdate>

          <ComercialUpdatePass v-bind:itemEntrada="item"></ComercialUpdatePass>
        </template>
        <template v-slot:[`item.estados`]="{ item }">
          <ComercialUpdateEstado
            v-bind:itemEntrada="item"
          ></ComercialUpdateEstado>
        </template>
        <template v-slot:[`item.estadoActivo`]="{ item }">
          <v-chip
            class="ma-2"
            color=" primary"
            v-show="item.estadoActivo"
            text-color="white"
          >
            Activo
          </v-chip>
          <v-chip
            class="ma-2"
            color="orange"
            v-show="!item.estadoActivo"
            text-color="white"
          >
            Desactivado
          </v-chip>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar"
        :bottom="true"
        :right="true"
        :timeout="5000"
        :color="snack_color"
      >
        {{ snack_text }}
        <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
      </v-snackbar>
    </v-card>
  </v-container>
</template>
<script>
import ComercialUpdate from './ComercialUpdateForm.vue'
import ComercialUpdateEstado from './ComercialUpdateEstado.vue'
import ComercialUpdatePass from './ComercialUpdatePass.vue'
import ComercialAdd from './ComercialAdd.vue'
export default {
    name: 'ComercialesTable',

    components: {
        ComercialUpdate,
        ComercialAdd,
        ComercialUpdateEstado,
        ComercialUpdatePass
    },
    data () {
        return {
            headerProps: {
                sortByText: 'Ordenar por'
            },
            snackbar: false,
            snack_color: '',
            snack_text: '',
            url: process.env.VUE_APP_API_URL,
            search: '',
            comerciales: [],
            headers: [
                { text: 'Nombre', align: 'left', value: 'nombre' },
                { text: 'Email', align: 'left', value: 'email' },
                { text: 'Estado', align: 'right', value: 'estados', sortable: false },
                { text: 'Acciones', align: 'right', value: 'action', sortable: false }
            ]
        }
    },
    computed: {
        VComercialesEliminar () {
            return this.$store.getters.getComercialesEliminar
        },
        VComercialesEditar () {
            return this.$store.getters.getComercialesEditar
        },
        VComercialesInsertar () {
            return this.$store.getters.getComercialesInsertar
        }
    },

    watch: {
        VComercialesEditar (value) {
            this.toastEmitirEditar(value)
        },
        VComercialesInsertar (value) {
            this.toastEmitirInsertar(value)
        }
    },

    methods: {
        go (msg, color) {
            this.snack_text = msg
            this.snack_color = color
            this.snackbar = true
        },
        toastEmitirEditar (update_result) {
            if (update_result) {
                if (update_result.data.success === true) {
                    this.listComerciales()
                    this.go('Modificación aplicada.', 'primary')
                } else {
                    if (update_result.status === 422) {
                        this.go('Ya existe un comerciales asociado a ese email.', 'error')
                    } else {
                        this.go(
                            'Se ha producido un error aplicando la modificación.',
                            'error'
                        )
                    }
                }
            } else {
                this.go('Se ha producido un error aplicando la modificación.', 'error')
            }
        },
        toastEmitirInsertar (insert_result) {
            if (insert_result) {
                if (insert_result.data.success === true) {
                    this.go('Creado correctamente!', 'primary')
                    this.listComerciales()
                } else {
                    if (insert_result.status === 422) {
                        this.go('Ya existe un comerciales asociado a ese email.', 'error')
                    } else {
                        this.go(
                            'Se ha producido un error al crear el comerciales.',
                            'error'
                        )
                    }
                }
            } else {
                this.go('Se ha producido un error al crear el comercial.', 'error')
            }
        },

        listComerciales () {
            const getComerciales = async () => {
                const config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: this.$store.getters.getToken
                    }
                }
                try {
                    return await this.$axios.get(
                        this.url + 'comerciales/' + this.$store.getters.getComercial.id,
                        config
                    )
                } catch (error) {
                    if (error.response.status === 401) {
                        this.$store.dispatch('setToken', '')
                        this.$store.dispatch('setComercial', '')
                        this.$router.push('/').catch(() => {})
                    }
                }
            }
            const processGetComerciales = async () => {
                const get_result = await getComerciales()
                if (get_result) {
                    this.$store.commit('SET_COMERCIALES', get_result.data.comerciales)
                    this.comerciales = this.$store.getters.getComerciales
                }
            }
            processGetComerciales()
        }
    },
    created () {
        this.listComerciales()
    }
}
</script>
<style>
</style>
