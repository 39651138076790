<template>
  <v-container>
    <v-layout>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialogoCrearComercial"
        @keydown.esc="dialogoCrearComercial = false"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on }">
          <v-btn class="mx-2" fab dark color="primary" v-on="on">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-col>
          <base-material-card color="primary" inline title="Nuevo comercial">
            <v-form class="pt-1">
              <v-text-field
                label="Nombre"
                v-model="nombreformnuevo"
                outlined
                class="ml-4"
              />
              <v-text-field
                label="Email"
                :rules="rulesEmail"
                v-model="correoformnuevo"
                outlined
                class="ml-4"
              />
              <v-text-field
                :rules="rules"
                type="password"
                label="Contraseña"
                v-model="passform1"
                outlined
                class="ml-4"
              />
              <v-text-field
                :rules="rules"
                type="password"
                label="Repita la contraseña"
                v-model="passform2"
                outlined
                class="ml-4"
              />

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialogoCrearComercial = false"
                  >Salir y volver</v-btn
                >
                <v-btn
                  id="checkLogin"
                  class="v-btn-login"
                  @click="confirmar()"
                  color="primary"
                  type="submit"
                  v-show="canGuardar"
                  form="check-login-form"
                  >Crear</v-btn
                >

                <v-btn
                  class="v-btn-login"
                  color="primary"
                  type="submit"
                  disabled
                  v-show="!canGuardar"
                  >Crear</v-btn
                >
              </v-card-actions>
            </v-form>
          </base-material-card>
        </v-col>
      </v-dialog>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :right="true"
      :timeout="5000"
      :color="snack_color"
    >
      {{ snack_text }}
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
    components: {
        'base-material-card': () => import('./base/MaterialCard.vue')
    },
    data: () => ({
        url: process.env.VUE_APP_API_URL,
        snackbar: false,
        snack_color: '',
        snack_text: '',
        correoformnuevo: '',
        nombreformnuevo: '',
        passform1: '',
        passform2: '',
        dialogoCrearComercial: false,
        rules: [
            (v) =>
                /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/.test(
                    v
                ) ||
        'Introduzca una contraseña de al menos 6 carácteres que tenga números y letras.'
        ],

        rulesEmail: [
            (v) =>
                /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
                    v
                ) || 'Introduzca una dirección de correo electronico.'
        ]
    }),
    computed: {
        canGuardar: function () {
            const patternCorreo =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/

            const patterPass =
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/
            return (
                this.correoformnuevo !== '' &&
        this.nombreformnuevo !== '' &&
        this.passform1 !== '' &&
        this.passform2 !== '' &&
        patternCorreo.test(this.correoformnuevo) &&
        patterPass.test(this.passform1) &&
        patterPass.test(this.passform2)
            )
        }
    },
    methods: {
        confirmar () {
            if (this.passform1 !== this.passform2) {
                this.go('Contraseñas diferentes', 'error')
            } else {
                this.crearComercial()
            }
        },
        crearComercial () {
            const comercial_insert = {
                email: this.correoformnuevo,
                nombre: this.nombreformnuevo,
                password: this.passform1,
                estadoActivo: true,
                padre: this.$store.getters.getComercial.id
            }
            const goInsert = async () => {
                const config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: this.$store.getters.getToken
                    }
                }
                try {
                    return await this.$axios.post(
                        this.url + '/comerciales',
                        comercial_insert,
                        config
                    )
                } catch (error) {
                    if (error.response.status === 401) {
                        this.$store.dispatch('setToken', '')
                        this.$store.dispatch('setComercial', '')
                        this.$router.push('/').catch(() => {})
                    }
                    if (error.response.status === 422) {
                        this.$store.commit('SET_COMERCIALES_INSERTAR', error.response)
                        this.dialogoCrearComercial = false
                    }
                }
            }
            const processgoInsertResult = async () => {
                const insert_result = await goInsert()
                if (insert_result) {
                    if (insert_result.data.success === true) {
                        this.$store.commit('SET_COMERCIALES_INSERTAR', insert_result)
                        this.passform2 = ''
                        this.passform1 = ''
                        this.correoformnuevo = ''
                        this.nombreformnuevo = ''
                        this.dialogoCrearComercial = false
                    } else {
                        this.$store.commit('SET_COMERCIALES_INSERTAR', insert_result)
                    }
                }
            }
            processgoInsertResult()
        },

        go (msg, color) {
            this.snack_text = msg
            this.snack_color = color
            this.snackbar = true
        }
    },
    created () {
        this.passform2 = ''
        this.passform1 = ''
        this.correoformnuevo = ''
        this.nombreformnuevo = ''
    }
}
</script>
